<template>
  <div>
    <b-tabs pills>
      <b-tab title="Ricerche" active>
        <b-card bg-variant="Default">
          <b-row class="match-height">
            <b-col
              md="11"
              cols="10" 
              class="mb-2"
            >
              <!-- input search -->
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
                </b-input-group-prepend>
                  <b-form-input v-model="campiRicerca.nome"
                      placeholder="ricerca generale"
                      type="text" />
                <b-input-group-append>
                  <b-button variant="outline-primary" @click.prevent="ricercaGenerale">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>

              </b-input-group>
            </b-col>
            <b-col
              md="1"
              cols="2"
              class="mb-2"
            >
              <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
            </b-col>
          </b-row>

            <!-- table -->
            <vue-good-table
            mode="remote"
            ref="table"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :rows="rows"
            :columns="columns"
            :isLoading=isLoading
            :search-options="{ 
                enabled: true,
                externalQuery: searchTerm }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            >
            
            <template slot="loadingContent">
              <div class="text-center my-3">
                  <b-spinner
                    variant="primary"
                    style="width: 3rem; height: 3rem;"
                    label="loading"
                  />
                  <div class="d-block pt-1 text-primary">Caricamento</div>
              </div>
            </template>
            
            <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>

            <template
                slot="table-row"
                slot-scope="props"
            >

                <div v-if="props.column.field === 'start_date'">
                  {{ formattazioneData(props.row.start_date, 'it') }}
                </div>

                <!-- Column: azioni -->
                <span v-else-if="props.column.field === 'azioni'">
                  <div class="text-nowrap">
                    <feather-icon
                      icon="ArrowRightIcon"
                      class="cursor-pointer text-primary"
                      size="21"
                      v-b-tooltip.hover.v-primary
                      title="Azioni"
                      :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                      @click="sidebarAction(props.row.id, props.row.id_customer, props.row.company.name, 
                      props.row.license.ref_code, props.row.license.type, props.row.state.id, props.row.state.name)"
                    />
                  </div>
                </span>

                <!-- Column: standard -->
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                    Visualizzazione da 1 a 
                    </span>
                    <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> di {{ props.total }} righe </span>
                </div>
                <div>
                    <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                    <template #prev-text>
                        <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        />
                    </template>
                    </b-pagination>
                </div>
                </div>
            </template>
            </vue-good-table>
                
        </b-card>
      </b-tab>

      <b-tab title="Pianificazione">
        <b-card bg-variant="Default">
          Pianificazione 

        </b-card>
                
      </b-tab>

      <b-tab title="Andamento">
        <b-card bg-variant="Default">
          Andamento 

        </b-card>
        
      </b-tab>


    </b-tabs>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.azienda"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col>
              <span class="font-weight-bold">Licenza N.:</span> {{ infoSideBar.licenza }}
            </b-col>
            <b-col>
              <span class="font-weight-bold">Licenza Tipo:</span> {{ infoSideBar.tipo_licenza }}
            </b-col>
            <b-col>
              <hr>
            </b-col>
            <b-col md="6">
              <span class="font-weight-bold">Stato ricerca:</span> <b-badge variant="light-success" v-if="infoSideBar.id_stato_licenza == 30">{{ infoSideBar.nome_stato_licenza }}</b-badge> <b-badge variant="light-danger" v-if="infoSideBar.id_stato_licenza != 30">{{ infoSideBar.nome_stato_licenza }}</b-badge>
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI RICERCA</div>
          </div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="$router.push({ name: 'crm-ricerche-show', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-ricerche-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          
          <HR />
          <P>da fare il cambio stato</P>

        </div>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTabs, BTab, BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BTabs,
    BTab,
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Azienda',
          field: 'company.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra per Cognome',
          }
        },
        {
          label: 'Data inizio',
          field: 'start_date',
          width: '120px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Licenza',
          field: 'license.ref_code',
          width: '120px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Stato',
          field: 'state.name',
          width: '180px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Obiettivo',
          field: 'objective',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
          thClass: 'd-none d-lg-table-cell',
          tdClass: 'd-none d-lg-table-cell',
        },
        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'user.name', text: 'Nome' },
        { value: 'user.surname', text: 'Cognome' },
        { value: 'user.email', text: 'Email' },
        { value: 'user.mobile_phone', text: 'Cellulare' },
        { value: 'user.role.display_name', text: 'Ruolo' },
        /* 
        { value: 'billing.region.name', text: 'Regione' },
        { value: 'billing.province.name', text: 'Provincia' },
        { value: 'colonna_inesistete', text: 'Colonna Errore' },
        { value: 'mobile_phone', text: 'Cellulare', notEnabled: true, disabled: true },
        {
          label: 'Raggruppamento',
          options: [
            { value: 'altro_1', text: 'Altro 1 (non usare)' },
            { value: 'altro_2', text: 'Altro 2 (non usare)' }
          ]
        }
        */
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },
      infoSideBar: {
        id: '',
        user_id: '',
        fullName: '',
        status: ''
      }
    }
  },
  computed: {
    
  },
  created() {
    this.loadItems();
  },
  methods: {
    formattazioneData(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY')
      } else {
        return '---'
      }
    },
    ricercaGenerale(){
      console.log("ricerca generale - chiamata Api per avere la ricerca su tutti i dati e NON solo sui dati dell'attuale viewport della tabella ;-)")
      console.log(this.campiRicerca)

      if((this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')){
        console.log("ok ricerca valida")

        //aggiorna parametri con la stessa funzione del filtro colonna ;-)
        this.updateParams({
          columnFilters: {
            [this.campiRicerca.sezione]: this.campiRicerca.nome
          },
        });

        //forza reset filtro colonne
        this.$refs.table.reset();

        this.loadItems();

        //attiva bottone "reset"
        this.resetState = 'attiva';
      }
    },
    clickReset(){
      console.log("clickReset");

      //forza reset filtro colonne
      this.$refs.table.reset();

      //forza reset parametri iniziali della tabella
      /*
      console.log("PRIMA ----")
      console.log(this.serverParams)
      this.serverParams = this.startServerParams;
      console.log("DOPO ----")
      console.log(this.serverParams)
      */
      this.updateParams(this.startServerParams);
      this.loadItems();

      //reset campi ricerca
      this.campiRicerca.nome = '';
      this.campiRicerca.sezione = null;
      //disattiva bottone "reset"
      this.resetState = 'hidden';
    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    sidebarAction(id, user_id, azienda, licenza, tipo_licenza, id_status, status) {
      this.infoSideBar = { 
        id: id,
        user_id: user_id,
        azienda: azienda,
        licenza: licenza,
        tipo_licenza: tipo_licenza,
        id_stato_licenza: id_status,
        nome_stato_licenza: status
      };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    
    convertiFormatoData(dataIngresso) {
      // Dividi la data in giorno, mese e anno
      var partiData = dataIngresso.split('/');

      // Crea una nuova data nel formato corretto (mm/dd/yyyy)
      var dataFormattata = new Date(partiData[2], partiData[1] - 1, partiData[0]);

      // Estrai le componenti della data
      var anno = dataFormattata.getFullYear();
      var mese = this.pad(dataFormattata.getMonth() + 1);
      var giorno = this.pad(dataFormattata.getDate());

      // Costruisci la data nel formato "yyyy-mm-dd"
      var dataConvertita = anno + '-' + mese + '-' + giorno;

      return dataConvertita;
    },
    pad(numero) {
      return numero < 10 ? '0' + numero : numero;
    },
    updateParams(newProps) {
      console.log(newProps)

      if (typeof newProps.columnFilters !== 'undefined'){

        //if(typeof newProps.columnFilters.start_date !== 'undefined')
        //typeof props.row.user === 'object' && props.row.start_date !== null
        if((typeof newProps.columnFilters["start_date"] !== 'undefined')){
          console.log("qui...")
          console.log(newProps.columnFilters["start_date"])

          //in caso di campo con "stringa" => vai di filtro per il valore richiesto
          if(newProps.columnFilters["start_date"] != ''){
            newProps.columnFilters["start_date"] = this.convertiFormatoData(newProps.columnFilters["start_date"]);
          }

          //in caso di campo in cui avevo impostato un filtro e lo voglio cancellare
          if(newProps.columnFilters["start_date"] == ''){
            delete(newProps.columnFilters["start_date"]);
          }
        }

      }

      //attiva bottone "reset" filtro o ricerca
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      console.log('cambio ordinamento')
      console.log(params)

      let field_ricerca = '';

      //colonne con relazione
      if(params[0].field == 'user.role'){
        field_ricerca = 'user.role_id';
      } else if(params[0].field == 'aggregato_name_surname'){
        field_ricerca = 'user.surname';
      } else {
        field_ricerca = params[0].field;
      }

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      this.isLoading = true;

      this.$http.get('api/crm/research/list', {
        params: this.serverParams 
        }).then(response => { 
          /*
          console.log("risposta vvv"); 
          console.log(response.data)
          console.log('--- ---')
          console.dir(response.data.reply.data)
          console.log("response.data.reply.meta.total -> "+response.data.reply.meta.total);
          */

          if(response.data.statusCode == 200){

            this.totalRecords = response.data.reply.meta.total;
            this.rows = response.data.reply.data;

          } else if(response.data.statusCode == 422){
            //errori con testo di notifica ok dall'api
            alert("messaggio -> "+response.data.reply)
            this.$swal({
              title: 'Errore',
              text: 'codice errore: '+response.data.reply,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
                textButton: 'Chiudi',
              },
              buttonsStyling: false,
            })
          } else {
            //tutto gli altri possibili errori
            this.$swal({
              title: 'Errore Generico',
              text: 'codice errore: '+response.data.statusCode,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
                textButton: 'Chiudi',
              },
              buttonsStyling: false,
            })
          }
          
          //rimuovi il loading dopo il caricamento dei dati nella tabella
          this.isLoading = false;
        })

    },
    cancellaRiga(id_riga){
      console.log("click cancellaRiga ...... id_riga -> "+id_riga);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione dell'utente",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/employee/delete/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>